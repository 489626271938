.shapes-footer {
    @include aspect-ratio(327, 96);
    padding: 0 24px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('../assets/svg/shapes-footer-mobile.svg');
    margin: 72px auto 0;

    @media (min-width: $br-md) {
        @include aspect-ratio(1583, 250);
        padding: 0;
        max-width: 1583px;
        background-image: url('../assets/svg/shapes-footer.svg');
        margin: 72px auto 0;
    }
}