.header-top {
    display: flex;
    align-items: center;
    margin-top: 40px;

    .header-top-bar {
        height: 1px;
        flex: 1;
        background-color: $light-gray;
        display: none;

    }

    .header-top-logo {
        @include aspect-ratio(109, 118);
        width: 109px;
        margin: 0 auto;
        margin-bottom: 50px;
        background-image: url('../assets/svg/logo.svg');
    }
}
