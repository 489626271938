.speakers {

    display: flex;
    position: relative;
    flex-direction: column;

    @media (min-width: $br-md) {
        flex-direction: row;
    }

    .speakers-shape {
        @include aspect-ratio(100, 200);
        left: calc(100% - 100px);
        top: calc(100% - 50px);
        max-width: 100px;
        background-image: url('../assets/svg/shape-7.svg');
        display: none;

        @media (min-width: $br-md) {
            display: block;
        }

        @media (min-width: 1520px) {
            left: calc(100% - 50px);
        }
    }

    .speakers-shape-mobile {
        @include aspect-ratio(24, 96);
        max-width: 24px;
        background-image: url('../assets/svg/shape-10.svg');
        top: calc(100% - 64px);
        right: -24px;
        @media (min-width: $br-md) {
            display: none;
        }
    }

    .speaker {
        flex-basis: 100%;
        background-color: $darkish-purple;
        color: $white;
        padding: 64px 24px;
        position: relative;
        margin-bottom: 16px;

        @media (min-width: $br-md) {
            padding: 80px 40px 64px;
        }

        @media (min-width: $br-lg) {
            padding: 105px 40px 86px;
        }

        &.has-shape {
            &:after {
                content: '';
                display: block;
                position: absolute;
                width: 24px;
                height: 48px;
                top: calc(100% - 16px);
                left: -24px;
                background-image: url('../assets/svg/shape-11.svg');

                @media (min-width: $br-md) {
                    display: none;
                }
            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        @media (min-width: $br-md) {
            flex-basis: 33.33333%;
            margin-right: 16px;
            margin-bottom: 0;

            &:last-child {
                margin-right: 0;
            }
        }

        .button {
            position: absolute;
            left: 24px;
            top: 24px;
        }

        .speaker-image {
            @include aspect-ratio(1);
            width: 80%;
            max-width: 180px;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            border-radius: 50%;
            margin: 0 auto 32px;

            @media (min-width: $br-md) {
                max-width: 240px;
                margin: 0 auto 48px;
            }
        }

        .speaker-name {
            font-size: 24px;
            margin-bottom: 20px;

            @media (min-width: $br-md) {
                font-size: 32px;
            }

        }

        .speaker-description {
            font-size: 15px;
            line-height: 1.47;
            // margin-bottom: 0;
            max-width:  300px;
            margin: 0 auto;

            @media (min-width: $br-md) {
                font-size: 16px;
                line-height: 1.5;
                max-width: none;
            }
        }
    }
}

