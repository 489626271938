.content-text {
    margin-bottom: 80px;
    position: relative;

    .shapes {
        .shape-1 {
            @include aspect-ratio(1);
            left: -130px;
            top: 21%;
            max-width: 100px;
            background-image: url('../assets/svg/shape-4.svg');
            display: none;

            @media (min-width: 970px) {
                display: block;
            }

            @media (min-width: 1180px) {
                left: -230px;

            }
        }

        .shape-2 {
            @include aspect-ratio(100, 150);
            right: -130px;
            top: 44%;
            max-width: 100px;
            background-image: url('../assets/svg/shape-2.svg');
            display: none;

            @media (min-width: 980px) {
                display: block;
            }

            @media (min-width: 1170px) {
                right: -230px;
            }
        }

        .shape-3 {
            @include aspect-ratio(1);
            left: -142px;
            top: 88%;
            max-width: 100px;
            background-image: url('../assets/svg/shape-3.svg');
            display: none;

            @media (min-width: 1020px) {
                display: block;
            }

            @media (min-width: 1430px) {
                left: -342px;

            }
        }

        .shape-4 {
            @include aspect-ratio(1,2);
            left: -24px;
            top: -32px;
            max-width: 24px;
            background-image: url('../assets/svg/shape-12.svg');

            @media (min-width: $br-md) {
                display: none;

            }
        }

        .shape-5 {
            @include aspect-ratio(1);
            left: calc(100% - 64px);
            top: calc(100% + 24px);
            max-width: 48px;
            background-image: url('../assets/svg/shape-1.svg');

            @media (min-width: $br-md) {
                display: none;
            }
        }
    }

    .h2 {
        margin-bottom: 40px;
        text-align: center;

        @media (min-width: $br-md) {
            text-align: left;
        }
    }

    .read-more-button {
        @media (min-width: $br-md) {
            display: none;
        }

        &.is-active {
            display: none;
        }
    }

    .read-more {
        display: none;

        @media (min-width: $br-md) {
            display: block;
        }

        &.is-active {
            display: block;
        }
    }
}

.single {
    .content-text {

        .paragraph {
            margin-bottom: 72px;
        }

        .shapes {
            .shape-1 {
                top: 30%;
            }

            .shape-3 {
                top: 62%;
            }

            .shape-4 {
                display: block;
                left: auto;
                right: -130px;
                top: 22%;
                background-image: url('../assets/svg/shape-7.svg');
                max-width: 100px;
                display: none;

                @media (min-width: 980px) {
                    display: block;
                }

                @media (min-width: 1200px) {
                    right: -230px;

                }

                @media (min-width: 1380px) {
                    right: -330px;
                }

            }

            .shape-5 {
                display: block;
                max-width: 100px;
                top: 83%;
                left: auto;
                right: -130px;
                background-image: url('../assets/svg/shape-4.svg');
                display: none;

                @media (min-width: 980px) {
                    display: block;
                }

                @media (min-width: 1200px) {
                    right: -230px;

                }

                @media (min-width: 1380px) {
                    right: -330px;
                }
            }
        }

        .h3 {
            &.has-shape {
                position: relative;
                display: block;

                &:before {
                    content: '';
                    position: absolute;
                    background-size: contain;
                    background-position: center center;
                    background-repeat: no-repeat;

                    @media (min-width: $br-md) {
                        display: none;
                    }
                }
            }

            &.is-shape-1 {
                &:before {
                    width: 48px;
                    height: 96px;
                    right: 0;
                    bottom: calc(100% + 56px);
                    background-image: url('../assets/svg/shape-7.svg');
                }
            }

            &.is-shape-2 {
                &:before {
                    width: 24px;
                    height: 48px;
                    right: 0;
                    bottom: calc(100% + 6px);
                    background-image: url('../assets/svg/shape-12.svg');
                }
            }

            &.is-shape-3 {
                &:before {
                    width: 48px;
                    height: 24px;
                    right: 0;
                    bottom: calc(100% + 24px);
                    background-image: url('../assets/svg/shape-13.svg');
                }
            }
        }
    }
}
