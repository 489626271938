@font-face {
    font-family: 'studiofeixen-book';
    src: url('../fonts/StudioFeixenSerif-Book.eot');
    src: url('../fonts/StudioFeixenSerif-Book.eot') format('embedded-opentype'),
    url('../fonts/StudioFeixenSerif-Book.woff2') format('woff2'),
    url('../fonts/StudioFeixenSerif-Book.woff') format('woff'),
    url('../fonts/StudioFeixenSerif-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'studiofeixen-regular';
    src: url('../fonts/StudioFeixenSerif-Regular.eot');
    src: url('../fonts/StudioFeixenSerif-Regular.eot') format('embedded-opentype'),
    url('../fonts/StudioFeixenSerif-Regular.woff2') format('woff2'),
    url('../fonts/StudioFeixenSerif-Regular.woff') format('woff'),
    url('../fonts/StudioFeixenSerif-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir Roman';
    src: url('../fonts/3AEB29_0_0.eot');
    src: url('../fonts/3AEB29_0_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/3AEB29_0_0.woff2') format('woff2'),
    url('../fonts/3AEB29_0_0.woff') format('woff'),
    url('../fonts/3AEB29_0_0.ttf') format('truetype');
}
