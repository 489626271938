.footer {
    background-color: $white;

    .footer-column {
        padding: 0 24px;

        @media (min-width: $br-md) {
            flex-basis: 33.3333%;

        }
    }

    .footer-top {

        font-size: 16px;
        display: flex;
        flex-direction: column;
        padding: 60px 0;
        margin: 0 -24px;
        text-align: center;
        align-items: center;
        line-height: 1.6;
        font-size: 15px;

        @media (min-width: $br-md) {
            display: flex;
            flex-direction: row;
            padding: 96px 0;
            font-size: 16px;
        }

        .footer-top-logo {
            width: 100%;
            max-width: 80px;
            padding: 0;
            margin-bottom: 40px;
            margin-left: -14px;

            @media (min-width: $br-md) {
                display: none;

            }
        }

        .footer-email {
            margin-bottom: 32px;

            @media (min-width: $br-md) {
                margin-bottom: 0;
                text-align: left;
            }
        }

        .footer-address {
            margin-bottom: 32px;
            @media (min-width: $br-md) {
                margin-bottom: 0;
                text-align: center;
            }
        }

        .footer-phone {
            @media (min-width: $br-md) {
                text-align: right;
            }
        }
    }

    .footer-divider {
        height: 1px;
        background-color: $light-gray;

    }

    .footer-bottom {

        font-size: 14px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 40px 0 88px;
        margin: 0 -24px;

        @media (min-width: $br-md) {
            padding: 64px 0;
            flex-direction: row;
        }

        .footer-logos {

            width: 100%;

            @media (min-width: $br-md) {
                display: flex;
                align-items: flex-end;
                margin-top: -7px;
            }

            .responsive-logo {
                display: none;
                width: 47px;
                min-width: 47px;

                @media (min-width: $br-md) {
                    display: block;
                    margin-right: 24px;
                }

                @media (min-width: $br-xl) {
                    margin-right: 58px;
                }
            }

            .eu-logo {
                width: 204px;
                margin: 0 auto;
                margin-bottom: 40px;

                @media (min-width: $br-md) {
                    margin: 0;
                }
            }
        }

        .footer-bottom-legal {
            display: flex;
            justify-content: center;
            margin-bottom: 40px;

            @media (min-width: $br-md) {
                margin-bottom: 0;
            }

            .footer-bottom-copyright {
                margin-left: 32px;
                opacity: 0.5;
            }
        }

        .footer-socials {
            display: flex;
            justify-content: flex-end;

            .social {
                margin-right: 32px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
