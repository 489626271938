.partners {
    .button {
        margin-bottom: 32px;
    }

    ul {

        list-style: none;
        padding: 0;
        margin: 0;

        li {
            line-height: 2.11;

            a {

            }
        }
    }
}
