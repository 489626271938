// breakpoints
$br-sm: 480px;
$br-md: 765px;
$br-lg: 1020px;
$br-xl: 1140px;
$br-xxl: 1600px;
$br-hd: 1920px;

// colors
$light-gray: #dcdcdc;
$white: #fff;
$background: #fcfff5;
$darkish-purple: #662581;
$berry: #a3185d;
$black: #1d1d1d;
$yellowish: #dcdd13;
$darker-black: #111113;
$font-black: #20212c;
