.button {
    background-color: $berry;
    text-transform: uppercase;
    display: inline-block;
    font-weight: 900;
    font-size: 10px;
    line-height: 1;
    padding: 8px 12px;
    letter-spacing: 2px;
    color: $white;

    &.is-white {
        color: $berry;
        background-color: $white;
    }
}