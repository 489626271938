.header {

    position: relative;

    .header-content {

        padding-bottom: 105px;

        .header-date {
            font-size: 16px;
            line-height: 1.75;
            color: $black;
            margin-bottom: 16px;
        }

        .header-headline {
            margin-bottom: 16px;
        }

        .header-location {
            font-size: 16px;
            line-height: 1.75;
            color: $black;
            margin-bottom: 40px;

            @media (min-width: $br-md) {
                margin-bottom: 72px;
            }
        }

        .header-eu-logo {
            margin: 0 auto;
            max-width: 170px;

            @media (min-width: $br-md) {
                max-width: 228px;
            }
        }
    }

    .header-shapes {

        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;

        .shape-1 {
            @include aspect-ratio(1);
            left: 3%;
            top: 0;
            max-width: 50px;
            background-image: url('../assets/svg/shape-1.svg');

            @media (min-width: $br-md) {
                left: 16.08%;
                max-width: 100px;
            }
        }

        .shape-2 {
            @include aspect-ratio(100, 150);
            left: -24px;
            top: 50%;
            max-width: 50px;
            background-image: url('../assets/svg/shape-2.svg');

            @media (min-width: $br-md) {
                left: 0;
                max-width: 100px;
            }

            @media (min-width: 1650px) {
                left: -114px;

            }
        }

        .shape-3 {
            @include aspect-ratio(1, 1);
            right: 0;
            bottom: 0;
            max-width: 120px;
            background-image: url('../assets/svg/shape-5.svg');
            display: none;

            @media (min-width: 960px) {
                display: block;
                right: auto;
                max-width: 250px;
                bottom: -5%;
                left: 14.64%;
            }
        }

        .shape-4 {
            @include aspect-ratio(100, 150);
            left: 68.35%;
            top: 9%;
            max-width: 100px;
            background-image: url('../assets/svg/shape-8.svg');
            display: none;

            @media (min-width: $br-md) {
                display: block;
            }
        }

        .shape-5 {
            @include aspect-ratio(1, 1);
            left: calc(100% - 200px);
            top: 52%;
            max-width: 200px;
            background-image: url('../assets/svg/shape.svg');
            display: none;

            @media (min-width: $br-md) {
                display: block;
            }

            @media (min-width: 1520px) {
                left: 88.86%;
            }
        }

        .shape-6 {
            @include aspect-ratio(350, 200);
            left: calc(58.9% - 50px);
            bottom: -100px;
            max-width: 350px;
            background-image: url('../assets/svg/shape-6.svg');
            display: none;

            @media (min-width: $br-md) {
                display: block;
            }

            @media (min-width: 900px) {
                left: 58.9%;
            }
        }

        .shape-7 {
            @include aspect-ratio(24, 48);
            right: -24px;
            top: 40%;
            max-width: 24px;
            background-image: url('../assets/svg/shape-9.svg');

            @media (min-width: $br-md) {
                display: none;
            }
        }
    }
}

.single {
    .header-shapes {
        .shape-3, .shape-6 {
            display: none;
        }
    }
}
