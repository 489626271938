body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Avenir Roman', sans-serif;
    color: $font-black;
    letter-spacing: 0.2px;
    font-size: 16px;

    @media (min-width: $br-md) {
        font-size: 18px;
    }
}

p {
    line-height: 1.78;
    // letter-spacing: 0.2px;
    margin: 0;
    margin-bottom: 30px;
}

h1, h2, h3, h4 {
    margin: 0;
    font-family: 'studiofeixen-regular', sans-serif;
    font-weight: normal;
    color: $black;
    letter-spacing: normal;
}

.h1 {
    font-size: 64px;
    line-height: 1.11;

    @media (min-width: $br-md) {
        font-size: 148px;
    }
}

.h2 {
    font-size: 32px;
    line-height: 1.31;

    @media (min-width: $br-md) {
        font-size: 48px;
        line-height: 1.33;
    }
}

.h3 {
    font-size: 32px;
    margin: 58px 0 24px;
}
