* {
    box-sizing: border-box;
}

body {
    background-color: $background;
}

.container {
    max-width: 1416px;
    padding: 0 24px;
    margin: 0 auto;
}

.content-container {
    max-width: 676px;
    margin: 0 auto;
    padding-top: 120px;
}

.text-center {
    text-align: center;
}

.link {
    text-decoration: none;
    color: inherit;
    display: inline;
    position: relative;
    cursor: pointer;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0% 93%;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    transition: all 0.3s ease;

    &:visited {
        color: inherit;
    }

    &:hover {
        background-size: 100% 0;
    }

    &.is-berry {
        color: $berry;
    }

    &.is-white {
        color: $white;
    }

    &.is-yellowish {
        color: $yellowish;
        background-image: linear-gradient($yellowish, $yellowish);
    }

    &.no-underline {
        background-image: none;
    }
}

.single {}

.responsive-logo {
    @include aspect-ratio(46, 47);
    background-image: url('../assets/svg/responsive-logo.svg');
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.eu-logo {
    @include aspect-ratio(228, 42);
    width: 100%;
    max-width: 228px;
    background-image: url('../assets/svg/european-logo.svg');
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.social {
    @include aspect-ratio(24,24);
    display: block;
    width: 24px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;

    &-facebook {
        background-image: url('../assets/svg/facebook.svg');
    }

    &-twitter {
        background-image: url('../assets/svg/twitter.svg');
    }

    &-instagram {
        background-image: url('../assets/svg/instagram.svg');
    }
}

.shape {
    position: absolute;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    transition: all 0.3s ease;

}

.single {
    .content-container {
        padding-top: 0;

        .button {
            margin-top: 68px;
        }
    }
}

.hide {
    &-md {
        @media (min-width: $br-md) {
            display: none;
        }
    }
}

